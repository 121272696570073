
import { computed, defineComponent, ref } from 'vue';

import BaseCard from '@/components/base/BaseCard';
import BaseButton from '@/components/base/BaseButton.vue';
import { useStripe } from '@/mixins/useStripe';
import StripeSubscription from '@/types/StripeSubscription';
import Stripe from 'stripe';
import useFaunaDB from '@/mixins/useFaunaDB';
import RequestStatus from '@/constants/RequestStatus';
import { isUpgrade } from '@/utils/subscription';
import * as Sentry from '@sentry/vue';

const PriceCard = defineComponent({
  components: { BaseCard, BaseButton },

  emits: ['updated', 'no-payment-method'],

  props: {
    plan: {
      type: Object as () => Stripe.SubscriptionItem,
      required: true,
    },
    currentSubscription: {
      type: Object as () => StripeSubscription,
      required: true,
    },
    price: Number || String,
    hasPaymentMethod: Boolean,
  },

  setup(props, { emit }) {
    const { updateUserRecord } = useFaunaDB();
    const { updateSubscription } = useStripe();

    const isAawaitAction = ref<boolean>(false);
    const status = ref<RequestStatus>(RequestStatus.Initial);

    const getCorrectPrice = computed(() => {
      const amount = props.plan.price.unit_amount ? props.plan.price.unit_amount / 100 : 0;
      return amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    });
    const isCurrent = computed(() => (props.plan?.price.id === props.currentSubscription.plan.id));
    const isPlanUpgrade = computed(() => isUpgrade(
      props.plan.price.unit_amount,
      props.currentSubscription.plan.amount,
    ));

    const isLoading = computed(() => status.value === RequestStatus.Loading);
    const isError = computed(() => status.value === RequestStatus.Error);

    const onCancel = () => {
      isAawaitAction.value = false;
    };

    const onUpdate = () => {
      isAawaitAction.value = true;
    };

    const onConfirm = async () => {
      isAawaitAction.value = false;

      if (!props.hasPaymentMethod) {
        emit('no-payment-method');

        return;
      }

      status.value = RequestStatus.Loading;

      const result = await updateSubscription(
        props.currentSubscription,
        props.plan,
        isPlanUpgrade.value,
      );

      if (!result) {
        status.value = RequestStatus.Error;
        Sentry.captureException(new Error('Failed to update subscription'));

        return;
      }

      const response = await updateUserRecord();

      if (!response) {
        status.value = RequestStatus.Error;
        Sentry.captureException(new Error('Failed to update user record'));

        return;
      }

      status.value = RequestStatus.Success;
      emit('updated');
    };

    return {
      onConfirm,
      onUpdate,
      onCancel,
      isAawaitAction,
      getCorrectPrice,
      isCurrent,
      isPlanUpgrade,
      isLoading,
      isError,
    };
  },
});

export default PriceCard;
