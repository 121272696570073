
import {
  computed, defineComponent, onBeforeMount, ref,
} from 'vue';
import DefaultLayout from '@/components/layout/DefaultLayout.vue';
import Usage from '@/components/Membership/Usage.vue';
import Billing from '@/components/Membership/Billing.vue';
import Plans from '@/components/Membership/Plans.vue';
import CloseAccount from '@/components/Membership/CloseAccount.vue';
import { useStripe } from '@/mixins/useStripe';
import PaymentMethodRequiredModal from '@/components/modals/PaymentMethodRequiredModal.vue';
import Stripe from 'stripe';
import StripeSubscription from '@/types/StripeSubscription';
import RequestStatus from '@/constants/RequestStatus';
import BaseNotificationBanner from '@/components/base/BaseNotificationBanner.vue';
import { getRenewalDate, isUpgrade } from '@/utils/subscription';
import * as Sentry from '@sentry/vue';

const TheMembership = defineComponent({
  components: {
    DefaultLayout,
    Usage,
    Billing,
    Plans,
    CloseAccount,
    PaymentMethodRequiredModal,
    BaseNotificationBanner,
  },

  setup() {
    const {
      fetchCurrentSubscription,
      fetchAllSubscriptions,
      fetchPaymentMethods,
      redirectToCustomerPortal,
    } = useStripe();

    const subscriptions = ref<Stripe.SubscriptionItem[]>([]);
    const currentSubscription = ref<StripeSubscription | null>(null);
    const paymentMethods = ref<Stripe.PaymentMethod[] | null>([]);
    const updatePaymentMethodModal = ref<boolean>(false);
    const notificationBannerVisible = ref<boolean>(false);
    const isPlanUpgrade = ref<boolean>(false);

    const currentSubscriptionStatus = ref<RequestStatus>(RequestStatus.Initial);
    const allSubscriptionStatus = ref<RequestStatus>(RequestStatus.Initial);
    const paymentMethodsStatus = ref<RequestStatus>(RequestStatus.Initial);
    const redirectStatus = ref<RequestStatus>(RequestStatus.Initial);
    const userRecordStatus = ref<RequestStatus>(RequestStatus.Initial);

    const isRedirectToPortalStatusLoading = computed(() => redirectStatus.value === RequestStatus.Loading);

    const isError = computed(() => ([
      currentSubscriptionStatus,
      allSubscriptionStatus,
      paymentMethodsStatus,
      userRecordStatus,
    ].some((status) => status.value === RequestStatus.Error)));

    const isLoading = computed(() => ([
      currentSubscriptionStatus,
      allSubscriptionStatus,
      paymentMethodsStatus,
      userRecordStatus,
    ].some((status) => status.value === RequestStatus.Loading)));

    const hasPaymentMethod = computed(() => paymentMethods.value && paymentMethods.value.length > 0);
    const renewalDate = computed(() => currentSubscription.value && getRenewalDate(currentSubscription.value));
    const currentPlan = computed(() => (
      subscriptions.value.find(
        (sub) => sub && currentSubscription.value && sub.price?.id === currentSubscription.value.plan?.id,
      )
    ));

    const onRedirectToPortal = async () => {
      redirectStatus.value = RequestStatus.Loading;

      const response = await redirectToCustomerPortal();

      if (!response) {
        redirectStatus.value = RequestStatus.Error;

        return;
      }

      redirectStatus.value = RequestStatus.Success;
    };

    const onFetchCurrentSubscription = async (planUpdated = false) => {
      currentSubscriptionStatus.value = RequestStatus.Loading;

      const response = await fetchCurrentSubscription();

      if (!response) {
        currentSubscriptionStatus.value = RequestStatus.Error;
        Sentry.captureException(new Error('Can\'t fetch current subscription'));

        return;
      }

      if (planUpdated && currentSubscription.value) {
        isPlanUpgrade.value = isUpgrade(response.plan.amount, currentSubscription.value.plan.amount);
        notificationBannerVisible.value = true;
      }

      currentSubscriptionStatus.value = RequestStatus.Success;
      currentSubscription.value = response;
    };

    const onFechAllSubscriptions = async () => {
      allSubscriptionStatus.value = RequestStatus.Loading;

      const response = await fetchAllSubscriptions();

      if (!response) {
        allSubscriptionStatus.value = RequestStatus.Error;
        Sentry.captureException(new Error('Can\'t fetch all subscriptions'));

        return;
      }

      allSubscriptionStatus.value = RequestStatus.Success;

      subscriptions.value = response;
    };

    const onFetchPaymentMethods = async () => {
      paymentMethodsStatus.value = RequestStatus.Loading;

      const response = await fetchPaymentMethods();

      if (!response) {
        paymentMethodsStatus.value = RequestStatus.Error;
        Sentry.captureException(new Error('Can\'t fetch payment methods'));

        return;
      }

      paymentMethodsStatus.value = RequestStatus.Success;
      paymentMethods.value = response;
    };

    onBeforeMount(async () => {
      await Promise.all([
        onFechAllSubscriptions(),
        onFetchPaymentMethods(),
        onFetchCurrentSubscription(),
      ]);
    });

    return {
      isLoading,
      isError,
      isRedirectToPortalStatusLoading,
      currentSubscription,
      hasPaymentMethod,
      onFetchCurrentSubscription,
      renewalDate,
      onRedirectToPortal,
      subscriptions,
      updatePaymentMethodModal,
      notificationBannerVisible,
      isPlanUpgrade,
      currentPlan,
    };
  },
});

export default TheMembership;
