
import { defineComponent, computed } from 'vue';
import BaseModal from '@/components/base/BaseModal.vue';
import BaseButton from '@/components/base/BaseButton.vue';

const PaymentMethodRequiredModal = defineComponent({
  components: { BaseButton, BaseModal },

  emits: ['update:modelValue', 'redirect'],

  props: {
    modelValue: Boolean,
    loading: Boolean,
  },

  setup(props, { emit }) {
    const localValue = computed({
      get: (): boolean => props.modelValue,
      set: (value: boolean): void => emit('update:modelValue', value),
    });

    return { localValue };
  },
});

export default PaymentMethodRequiredModal;
