
import { defineComponent, inject } from 'vue';
import PriceCard from '@/components/Membership/PriceCard.vue';
import StripeSubscription from '@/types/StripeSubscription';
import Stripe from 'stripe';
import { getPlan } from '@/utils/subscription';
import SubscriptionPlans from '@/types/SubscriptionPlans';

const Plans = defineComponent({
  components: { PriceCard },

  emits: ['updated', 'no-payment-method'],

  props: {
    subscriptions: Object as () => Stripe.SubscriptionItem[],
    currentSubscription: Object as () => StripeSubscription,
    hasPaymentMethod: Boolean,
  },

  setup() {
    const { plans } = inject('PlansModule') as { plans: SubscriptionPlans };
    const getSubscriptionPlan = (priceId: string) => getPlan(plans, priceId);

    return { getSubscriptionPlan };
  },
});

export default Plans;
