
import {
  computed, defineComponent, inject,
} from 'vue';
import BaseCard from '@/components/base/BaseCard';
import AuthState from '@/types/AuthState';

const Usage = defineComponent({
  components: { BaseCard },

  props: {
    renewal: Date,
  },

  setup() {
    const { authState } = inject('AuthModule') as { authState: AuthState };

    const topicSearches = computed(() => authState.user.requestCount);
    const aiSearches = computed(() => authState.user.nlpRequestCount);

    return { topicSearches, aiSearches };
  },
});

export default Usage;
