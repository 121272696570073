import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "m-auto w-11/12 md:w-4/6 pb-20" }
const _hoisted_2 = { class: "text-center mt-20 mb-14" }
const _hoisted_3 = { class: "text-24 md:text-30 lg:text-36 mb-5 font-bold" }
const _hoisted_4 = { class: "text-11 text-dark uppercase font-semibold mb-2" }
const _hoisted_5 = { class: "mt-14" }
const _hoisted_6 = { class: "text-11 text-dark uppercase font-semibold mb-2" }
const _hoisted_7 = { class: "font-semibold" }
const _hoisted_8 = { class: "font-semibold" }
const _hoisted_9 = { class: "font-semibold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Usage = _resolveComponent("Usage")
  const _component_Billing = _resolveComponent("Billing")
  const _component_Plans = _resolveComponent("Plans")
  const _component_CloseAccount = _resolveComponent("CloseAccount")
  const _component_PaymentMethodRequiredModal = _resolveComponent("PaymentMethodRequiredModal")
  const _component_BaseNotificationBanner = _resolveComponent("BaseNotificationBanner")
  const _component_DefaultLayout = _resolveComponent("DefaultLayout")

  return (_openBlock(), _createBlock(_component_DefaultLayout, {
    loading: _ctx.isLoading,
    error: _ctx.isError
  }, {
    default: _withCtx(() => [
      _createVNode("div", _hoisted_1, [
        _createVNode("div", _hoisted_2, [
          _createVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t('membership.title')), 1)
        ]),
        _createVNode(_component_Usage, {
          renewal: _ctx.renewalDate,
          class: "mb-12"
        }, null, 8, ["renewal"]),
        _createVNode(_component_Billing, {
          class: "mb-12",
          loading: _ctx.isRedirectToPortalStatusLoading,
          onRedirectToCustomerPortal: _ctx.onRedirectToPortal
        }, null, 8, ["loading", "onRedirectToCustomerPortal"]),
        _createVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('membership.plans.label')), 1),
        (_ctx.currentSubscription && _ctx.subscriptions.length > 0)
          ? (_openBlock(), _createBlock(_component_Plans, {
              key: 0,
              subscriptions: _ctx.subscriptions,
              "current-subscription": _ctx.currentSubscription,
              "has-payment-method": _ctx.hasPaymentMethod,
              onNoPaymentMethod: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updatePaymentMethodModal = true)),
              onUpdated: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onFetchCurrentSubscription(true)))
            }, null, 8, ["subscriptions", "current-subscription", "has-payment-method"]))
          : _createCommentVNode("", true),
        _createVNode("div", _hoisted_5, [
          _createVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('membership.close-account.label')), 1),
          (_ctx.currentSubscription && _ctx.subscriptions.length > 0)
            ? (_openBlock(), _createBlock(_component_CloseAccount, {
                key: 0,
                "renewal-date": _ctx.renewalDate,
                subscriptions: _ctx.subscriptions,
                "current-subscription": _ctx.currentSubscription,
                onUpdated: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onFetchCurrentSubscription(true)))
              }, null, 8, ["renewal-date", "subscriptions", "current-subscription"]))
            : _createCommentVNode("", true)
        ]),
        _createVNode(_component_PaymentMethodRequiredModal, {
          modelValue: _ctx.updatePaymentMethodModal,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.updatePaymentMethodModal = $event)),
          loading: _ctx.isRedirectToPortalStatusLoading,
          onRedirect: _ctx.onRedirectToPortal
        }, null, 8, ["modelValue", "loading", "onRedirect"]),
        (_ctx.notificationBannerVisible)
          ? (_openBlock(), _createBlock(_component_BaseNotificationBanner, {
              key: 1,
              title: _ctx.isPlanUpgrade ? _ctx.$t('subscription-upgraded.title') : _ctx.$t('subscription-downgraded.title'),
              onHide: _cache[5] || (_cache[5] = ($event: any) => (_ctx.notificationBannerVisible = false))
            }, {
              default: _withCtx(() => [
                (_ctx.isPlanUpgrade)
                  ? (_openBlock(), _createBlock(_Fragment, { key: 0 }, [
                      _createTextVNode(_toDisplayString(_ctx.$t('subscription-upgraded.message')) + " ", 1),
                      _createVNode("span", _hoisted_7, _toDisplayString(_ctx.currentPlan.name), 1)
                    ], 64))
                  : (_openBlock(), _createBlock(_Fragment, { key: 1 }, [
                      _createVNode("div", null, [
                        _createTextVNode(_toDisplayString(_ctx.$t('subscription-downgraded.message-one')) + " ", 1),
                        _createVNode("span", _hoisted_8, _toDisplayString(_ctx.currentPlan.name), 1)
                      ]),
                      _createVNode("div", null, [
                        _createTextVNode(_toDisplayString(_ctx.$t('subscription-downgraded.message-two')) + " ", 1),
                        _createVNode("span", _hoisted_9, _toDisplayString(_ctx.$d(_ctx.renewalDate, 'short')), 1)
                      ])
                    ], 64))
              ]),
              _: 1
            }, 8, ["title"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["loading", "error"]))
}