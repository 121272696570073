import { toDisplayString as _toDisplayString, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "my-12 w-80" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")
  const _component_BaseModal = _resolveComponent("BaseModal")

  return (_ctx.localValue)
    ? (_openBlock(), _createBlock(_component_BaseModal, {
        key: 0,
        "modal-classes": "px-20",
        title: _ctx.$t('payment-method-required-modal.title'),
        onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.localValue = false))
      }, {
        default: _withCtx(() => [
          _createVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('payment-method-required-modal.message')), 1),
          _createVNode(_component_BaseButton, {
            loading: _ctx.loading,
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('redirect')))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('actions.add-payment-method')), 1)
            ]),
            _: 1
          }, 8, ["loading"])
        ]),
        _: 1
      }, 8, ["title"]))
    : _createCommentVNode("", true)
}