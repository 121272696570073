import { toDisplayString as _toDisplayString, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "text-13 my-5 text-secondary-dark" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")
  const _component_BaseModal = _resolveComponent("BaseModal")

  return (_ctx.localValue)
    ? (_openBlock(), _createBlock(_component_BaseModal, {
        key: 0,
        title: _ctx.$t('membership.close-account.we-are-sorry-you-go'),
        "modal-classes": "w-5/6 lg:w-1/3 px-8",
        onClose: _ctx.onClose
      }, {
        default: _withCtx(() => [
          _createVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('membership.close-account.account-has-been-deleted')), 1),
          _createVNode(_component_BaseButton, {
            class: "mr-3 w-full md:w-2/5 min-w-max text-13",
            variant: "primary",
            outline: "",
            onClick: _ctx.onClose
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('actions.close')), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      }, 8, ["title", "onClose"]))
    : _createCommentVNode("", true)
}