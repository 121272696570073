
import { defineComponent } from 'vue';
import BaseButton from '@/components/base/BaseButton.vue';

const Billing = defineComponent({
  components: { BaseButton },

  emits: ['redirect-to-customer-portal'],

  props: {
    loading: Boolean,
  },
});

export default Billing;
