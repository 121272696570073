import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")
  const _component_CloseAccountStepOneModal = _resolveComponent("CloseAccountStepOneModal")
  const _component_CloseAccountStepTwoModal = _resolveComponent("CloseAccountStepTwoModal")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_BaseButton, {
      class: " font-semibold text-13",
      variant: "close",
      onClick: _ctx.onAccountClose
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('membership.close-account.button-text')), 1)
      ]),
      _: 1
    }, 8, ["onClick"]),
    _createVNode(_component_CloseAccountStepOneModal, {
      modelValue: _ctx.firstStep,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.firstStep = $event)),
      "renewal-date": _ctx.renewalDate,
      subscriptions: _ctx.subscriptions,
      "current-subscription": _ctx.currentSubscription,
      onUpdated: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('updated'))),
      onCancel: _ctx.onCancel,
      onDeleted: _ctx.onDeleted
    }, null, 8, ["modelValue", "renewal-date", "subscriptions", "current-subscription", "onCancel", "onDeleted"]),
    _createVNode(_component_CloseAccountStepTwoModal, {
      modelValue: _ctx.secondStep,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.secondStep = $event))
    }, null, 8, ["modelValue"])
  ], 64))
}