import { toDisplayString as _toDisplayString, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mb-2 flex" }
const _hoisted_2 = { class: "text-11 text-dark uppercase font-semibold" }
const _hoisted_3 = { class: "inline-block bg-primary-light text-primary-light px-6 py-2 rounded-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")

  return (_openBlock(), _createBlock("div", null, [
    _createVNode("div", _hoisted_1, [
      _createVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('membership.billing.label')), 1)
    ]),
    _createVNode("div", _hoisted_3, [
      _createVNode(_component_BaseButton, {
        class: "hover:opacity-75",
        link: "",
        "loading-variant": "primary",
        loading: _ctx.loading,
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('redirect-to-customer-portal')))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('membership.billing.view-billing')), 1)
        ]),
        _: 1
      }, 8, ["loading"])
    ])
  ]))
}