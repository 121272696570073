import StripeSubscription from '@/types/StripeSubscription';
import SubscriptionPlans from '@/types/SubscriptionPlans';

export const getPlan = (plans: SubscriptionPlans, priceId: string) => Object.values(plans)
  .find((sub) => sub.price === priceId);

export const getRenewalDate = (subscription: StripeSubscription) => new Date(subscription.current_period_end * 1000);

export const isUpgrade = (firstAmount: number | null, secondAmount: number | null) => {
  if (firstAmount === null || secondAmount === null) return false;
  return firstAmount > secondAmount;
};
