
import { defineComponent, ref } from 'vue';
import BaseButton from '@/components/base/BaseButton.vue';
import CloseAccountStepOneModal from '@/components/modals/CloseAccountStepOneModal.vue';
import CloseAccountStepTwoModal from '@/components/modals/CloseAccountStepTwoModal.vue';
import StripeSubscription from '@/types/StripeSubscription';
import Stripe from 'stripe';

const CloseAccount = defineComponent({
  components: { BaseButton, CloseAccountStepOneModal, CloseAccountStepTwoModal },

  emits: ['updated'],

  props: {
    subscriptions: {
      type: Object as () => Stripe.SubscriptionItem[],
      required: true,
    },
    currentSubscription: {
      type: Object as () => StripeSubscription,
      required: true,
    },
    renewalDate: Date,
  },

  setup() {
    const firstStep = ref<boolean>(false);
    const secondStep = ref<boolean>(false);

    const onCancel = () => {
      firstStep.value = false;
    };

    const onAccountClose = () => {
      firstStep.value = true;
    };

    const onDeleted = () => {
      firstStep.value = false;
      secondStep.value = true;
    };

    return {
      firstStep,
      secondStep,
      onAccountClose,
      onDeleted,
      onCancel,
    };
  },
});

export default CloseAccount;
