import { toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "text-13 my-5 text-secondary-dark" }
const _hoisted_2 = { class: "font-semibold" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 2,
  class: "text-center mt-2 text-input-danger"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")
  const _component_BaseModal = _resolveComponent("BaseModal")

  return (_ctx.localValue)
    ? (_openBlock(), _createBlock(_component_BaseModal, {
        key: 0,
        title: _ctx.$t('membership.close-account.confirm-deletion'),
        "modal-classes": "w-5/6 lg:w-2/6 px-8",
        onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.localValue = false))
      }, {
        default: _withCtx(() => [
          _createVNode("div", _hoisted_1, [
            _createTextVNode(_toDisplayString(_ctx.$t('membership.close-account.confirm-text-part-one', { count: _ctx.requestLeft })) + " ", 1),
            _createVNode("span", _hoisted_2, _toDisplayString(_ctx.$d(_ctx.renewalDate, 'short')), 1),
            _createTextVNode(". " + _toDisplayString(_ctx.$t('membership.close-account.confirm-text-part-two')) + " ", 1),
            (_ctx.previousPlan)
              ? (_openBlock(), _createBlock("div", _hoisted_3, _toDisplayString(_ctx.$t('membership.close-account.to-keep-using')), 1))
              : _createCommentVNode("", true)
          ]),
          (_ctx.previousPlan)
            ? (_openBlock(), _createBlock(_component_BaseButton, {
                key: 0,
                class: "w-full md:w-2/5 mr-3 text-13",
                outline: "",
                loading: _ctx.isDowngradeLoading,
                onClick: _ctx.onDowngrade
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('actions.downgrade-plan')), 1)
                ]),
                _: 1
              }, 8, ["loading", "onClick"]))
            : (_openBlock(), _createBlock(_component_BaseButton, {
                key: 1,
                class: "w-full md:w-2/5 mr-3 text-13",
                outline: "",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('cancel')))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('actions.cancel')), 1)
                ]),
                _: 1
              })),
          _createVNode(_component_BaseButton, {
            class: "mr-3 w-full mt-2 md:mt-0 md:w-2/5 text-13",
            variant: "danger",
            loading: _ctx.isDeleteLoading,
            onClick: _ctx.onDeleteConfirmed
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('actions.confirm-deletion')), 1)
            ]),
            _: 1
          }, 8, ["loading", "onClick"]),
          (_ctx.isDowngradeError || _ctx.isDeleteError)
            ? (_openBlock(), _createBlock("div", _hoisted_4, _toDisplayString(_ctx.$t('errors.system')), 1))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["title"]))
    : _createCommentVNode("", true)
}