
import {
  defineComponent, computed, ref, inject,
} from 'vue';
import BaseModal from '@/components/base/BaseModal.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import StripeSubscription from '@/types/StripeSubscription';
import Stripe from 'stripe';
import { useStripe } from '@/mixins/useStripe';
import RequestStatus from '@/constants/RequestStatus';
import useFaunaDB from '@/mixins/useFaunaDB';
import AuthState from '@/types/AuthState';
import * as Sentry from '@sentry/vue';

const CloseAccountStepOneModal = defineComponent({
  components: { BaseModal, BaseButton },

  emits: ['update:modelValue', 'updated', 'deleted'],

  props: {
    modelValue: Boolean,
    subscriptions: {
      type: Object as () => Stripe.SubscriptionItem[],
      required: true,
    },
    currentSubscription: {
      type: Object as () => StripeSubscription,
      required: true,
    },
    renewalDate: Date,
  },

  setup(props, { emit }) {
    const { authState } = inject('AuthModule') as { authState: AuthState };

    const { deleteCustomer } = useStripe();
    const { deleteUserRecord, updateUserRecord } = useFaunaDB();
    const { updateSubscription } = useStripe();

    const downgradeStatus = ref<RequestStatus>(RequestStatus.Initial);
    const deleteStatus = ref<RequestStatus>(RequestStatus.Initial);

    const localValue = computed({
      get: (): boolean => props.modelValue,
      set: (value: boolean): void => emit('update:modelValue', value),
    });

    const isDowngradeLoading = computed(() => downgradeStatus.value === RequestStatus.Loading);
    const isDowngradeError = computed(() => downgradeStatus.value === RequestStatus.Error);

    const isDeleteLoading = computed(() => deleteStatus.value === RequestStatus.Loading);
    const isDeleteError = computed(() => deleteStatus.value === RequestStatus.Error);

    const requestLeft = computed(() => {
      const result = authState.user.currentNlpRequestLimit - authState.user.nlpRequestCount;
      return result && result < 0 ? 0 : result;
    });

    const previousPlan = computed(() => (
      // Subscriptions already sorted by amount in useStripe
      // so using pop() will return nearest plan
      props.subscriptions?.filter((subscription: Stripe.SubscriptionItem) => (
        subscription.price.unit_amount! < props.currentSubscription.plan.amount!)
      ).pop()

    ));

    const onDowngrade = async () => {
      if (!previousPlan.value || !props.currentSubscription) return;

      downgradeStatus.value = RequestStatus.Loading;

      const result = await updateSubscription(props.currentSubscription, previousPlan.value, false);

      if (!result) {
        downgradeStatus.value = RequestStatus.Error;
        Sentry.captureException(new Error('Failed to downgrade subscription'));

        return;
      }

      const response = await updateUserRecord();

      if (!response) {
        downgradeStatus.value = RequestStatus.Error;
        Sentry.captureException(new Error('Failed to update user record'));

        return;
      }

      downgradeStatus.value = RequestStatus.Success;
      localValue.value = false;
      emit('updated');
    };

    const onDeleteConfirmed = async () => {
      deleteStatus.value = RequestStatus.Loading;

      await Promise.all([
        deleteCustomer(),
        deleteUserRecord(),
      ]).then(() => {
        deleteStatus.value = RequestStatus.Success;
        emit('deleted');
      }).catch(() => {
        deleteStatus.value = RequestStatus.Error;
        Sentry.captureException(new Error('Failed to delete user record'));
      });
    };

    return {
      localValue,
      previousPlan,
      onDowngrade,
      isDowngradeLoading,
      isDeleteLoading,
      isDowngradeError,
      isDeleteError,
      requestLeft,
      onDeleteConfirmed,
    };
  },
});

export default CloseAccountStepOneModal;
