
import { defineComponent, computed, inject } from 'vue';
import BaseModal from '@/components/base/BaseModal.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import { useRouter } from 'vue-router';

const CloseAccountStepTwoModal = defineComponent({
  components: { BaseModal, BaseButton },

  emits: ['update:modelValue'],

  props: {
    modelValue: Boolean,
  },

  setup(props, { emit }) {
    const { logOut } = inject('AuthModule') as { logOut: Function };
    const router = useRouter();

    const localValue = computed({
      get: (): boolean => props.modelValue,
      set: (value: boolean): void => emit('update:modelValue', value),
    });

    const onClose = () => {
      logOut();
      router.push({ name: 'dashboard' });
    };

    return { localValue, onClose };
  },
});

export default CloseAccountStepTwoModal;
